table {
  background-color: transparent;
}

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777;
  text-align: left;
}

th {
  text-align: left;
}

// Baseline styles

table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;

  // Cells
  >thead,
  >tbody,
  >tfoot {
    >tr {

      >th,
      >td {
        padding: 8px;
        line-height: 1.428571429;
        vertical-align: top;
        border-top: 1px solid #ddd;
      }
    }
  }

  // Bottom align for column headings
  >thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
  }

  // Remove top border from thead by default
  >caption+thead,
  >colgroup+thead,
  >thead:first-child {
    >tr:first-child {

      >th,
      >td {
        border-top: 0;
      }
    }
  }

  // Account for multiple tbody instances
  >tbody+tbody {
    border-top: 2px solid #ddd;
  }

  // Nesting
  table {
    background-color: transparent;
  }
}
.mini-swiper {
    & > .swiper-container {
        height: auto;
        margin: 10px 5%;
        width: 90%;
        margin-bottom: -180px;

        .swiper-slide {
            flex-direction: column;

            .description-wrapper {
                background: rgba(64,64,64,.6);
                margin-top: 25px;
                padding: 30px;
            }
        }
    }
}

.section.section.main {
    .pull-bottom {
        z-index: 1020;
    }
}

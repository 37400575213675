.modal-backdrop.in {
    opacity: 0.93
}

.galleryModal {
    h1 {
        color: white;
    }

    h2 {
        color: white;
    }

    h3 {
        color: white;
    }

    .modal-title {
        color: white;
        font-size: 25px;
        font-weight: 500;
        border: 0px;
    }

    .modal-body {
        padding: 30px;

        .swiper-slide {
            //width: 800px !important;
            //height: auto !important;
        }

    }

    .modal-header {
        border: none;
        padding-bottom: 0px;
    }

    .modal-content {
        background-color: transparent;
        border: none;
        border-radius: 0px;
        box-shadow: none;
    }

    .modal-dialog {
        text-align: center;

        .neos-nodetypes-text {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .close {
        color: white;
        opacity: 1;
        font-size: 30px;

    }

    .swiper-button-next {
        display: none;
        top: 30%;
        background-image: none;
        text-align: center;

        i {
            font-size: 30px;
            color: white;
            width: 34px;
        }

        height: auto;
        width: auto;
        right: -5px;
    }

    .swiper-button-prev {
        display: none;
        text-align: center;
        top: 30%;
        background-image: none;

        i {
            font-size: 30px;
            color: white;
            width: 34px;
        }

        left: -5px;
        height: auto;
        width: auto;
    }
}

.modal p {
    color: white;
    margin: 0px;
    padding: 0px;
}

.modal .row {
    border-bottom: 0px solid black;
}

.thumbnailHoverOverlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 10;
}

.thumbnailHoverOverlay:hover {


    @media(min-width: $sm-view) {
        left: 15px;
        width: 330px;
        background-color: rgba(0.5, 0.5, 0.5, 0.4);
    }

    @media(min-width: $md-view) {
        left: 15px;
        width: 285px;
        background-color: rgba(0.5, 0.5, 0.5, 0.4);
    }

    @media(min-width: $lg-view) {
        left: 15px;
        width: 350px;
        background-color: rgba(0.5, 0.5, 0.5, 0.4);
    }



}

#TextHoverOverlayWrapper {
    display: block;
    position: absolute;
    bottom: 0px;
    text-align: center;
    left: 5%;
    width: 90%;


    @media(min-width: $sm-view) {
        left: 15px;
        width: 330px;
    }

    @media(min-width: $md-view) {
        left: 15px;
        width: 285px;
    }

    @media(min-width: $lg-view) {
        left: 15px;
        width: 350px;
    }



    background-color: rgba(0, 0, 0, 0.5);
    color: white;

    h3 {
        display: inline-block;
        font-size: 12px;
        font-weight: 600;
        //border-bottom: 1px solid white;
        padding-bottom: 5px;
        margin-bottom: 0px;
        color: white;
    }

    p {
        font-size: 10px;
        font-weight: 300;
        margin-bottom: 20px;
        color: white;
    }
}

.modalThumbnail {

    height: 300px;

    @media(min-width: $sm-view) {
        height: 240px;
    }

    @media(min-width: $md-view) {
        height: 240px;
    }

    @media(min-width: $lg-view) {
        height: 280px;
    }

    background-size: cover;
    background-repeat: no-repeat;
}

//MetaMenuModal
.metaMenuModal {
    .modal-header {
        border: none;
        padding-bottom: 0px;
        text-align: left;
        font-size: 25px;
        text-transform: uppercase;
        color: rgb(82, 82, 82);
    }

    .modal-title {
        color: rgb(82, 82, 82);
    }

    .modal-content {

        text-align: left;

        p {
            color: #525252;
            ;
            margin: 0px;
            padding: 0px;
        }

        b {
            color: black;
        }
    }

    .neos-nodetypes-text {
        padding: 0px;
    }
}

.modal {
    z-index: 10001;
}
@import url(http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,700);

html {
	position: relative;
	min-height: 100%;

	body {
		margin: 0px;
		padding: 0;
		font-family: 'Open Sans', 'Arial';
		font-weight: 300;
		color: #FFFFFF;

		@import 'table.scss';
		@import 'form.scss';
		@import 'modal.scss';

		footer {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 60px;
			padding-top: 10px;
			background-color: #eee;
		}

		p {
			color: #FFFFFF;
		}

		a,
		a:hover,
		a:focus {
			color: #85b221;
		}

		a[href^="tel:"] {
			color: inherit;
			text-decoration: none;
			cursor: default;
		}

		.collapse.in {
			min-height: 150px;
		}

		.dark-bg {
			background: rgba(60, 60, 59, 0.63);
		}

		.dark-bg:empty {
			padding: 0px;
			background: none;
		}

		.google-map-canvas {
			color: #808080;
		}

		.swiper-container .head {
			display: none;
		}

		section {
			position: relative;
			background-size: cover;

			&.section.main {
				//min-height: 601px;
				//min-height: 100vh;
			}

			.center-semitrans {
				margin-top: 40px;
				padding-top: 25px;
			}

			.dark-bg:empty {
				padding: 0px;
			}


			.dark-bg {
				background: rgba(60, 60, 59, 0.63);
			}

			.neos-nodetypes-html {
				ul {
					li {
						color: #575756;
					}
				}
			}


			.semitrans-fixedheight {
				min-height: 220px;
				margin-top: 40px;
				padding-top: 25px;
				padding-bottom: 25px;
			}


			h1,
			h2 {
				text-transform: uppercase;
				font-size: 31px;
			}

			h1 i,
			h2 i {
				font-style: normal;
				color: #85b221;
			}



		}

		.swiper-container .content {
			top: 90px;
		}

		.swiper-container {
			margin-bottom: 0;
			height: 100%;
		}

		.swiper-container.swiper-images {
			width: 80%;
			height: 430px;
			margin: 50px auto 0;
			padding: 50px 0;
		}

		.swiper-container.swiper-description {
			margin: 15px auto;
			width: 80%;
			text-align: center;
		}

		.swiper-container.swiper-description .swiper-slide>div {
			padding: 30px;
			font-size: 12px;
		}

		.swiper-container.swiper-description .swiper-slide p {
			margin: 0 0 15px;
		}

		.swiper-container.swiper-description .swiper-slide p:last-child {
			margin: 0;
		}

		.swiper-slide {
			align-items: center;
			display: flex;
			justify-content: center;
			text-align: center;
		}

		.swiper-slide h2 {

			font-size: 15px;

			@media (min-width: $sm-view) {
				font-size: 20px;
			}

			@media (min-width: $md-view) {
				font-size: 20px;
			}

			@media (min-width: $lg-view) {
				font-size: 30px;
			}

		}


		.swiper-images .swiper-slide img {
			width: 100%;
			height: auto;
			padding-top: 15%;
			transition: all 0.5s ease-in-out;
		}

		.swiper-images .swiper-slide.swiper-slide-prev img,
		.swiper-slide.swiper-slide-next img {
			width: 100%;
			padding-top: 10%;
		}

		.swiper-images .swiper-slide.swiper-slide-active img {
			width: 100%;
			padding-top: 0;
		}

		.swiper-button-prev,
		.swiper-button-next {
			left: 10px;
			top: 390px;
			background: none;
			color: #fff;
			font-size: 52px;
			transition: all 1s ease-in-out;
		}

		.swiper-button-next {
			left: auto;
			right: 10px;
		}

		.swiper-button-prev:hover,
		.swiper-button-next:hover {
			transform: scale(1.2);
		}

		.swiper-container.swiper-description {
			background: rgba(64, 64, 64, 0.6);
		}

		.section.section.main .pull-bottom {
			position: relative;
			width: 100%;
			bottom: 0;
			right: 0;
			margin-top: 50px;
		}

		.section .btn.btn-primary.collapse-button {
			position: absolute;
			bottom: 0px;
			right: 0;
			border-radius: 0;
			border-top-left-radius: 2px;
			border-top-right-radius: 0;
			background-color: #fff;
			border-color: #fff;
			color: #3c3c3c;
			min-width: 250px;
			box-shadow: none;
		}

		.section .btn.btn-primary.collapse-button:before {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			border-left: 55px solid transparent;
			border-bottom: 34px solid #fff;
			left: -55px;
			top: 0px;
		}

		.section .btn.btn-primary.collapse-button:after {
			content: ' >>';
			color: #85b221;
			font-size: 14px;
			font-weight: bold;
		}


		.section .btn.btn-primary.next-section-button {
			position: relative;
			display: none;
			width: 70px;
			left: calc(50% - 35px);
			bottom: 0;
			background-color: transparent;
			border-color: transparent;
			color: #fff;
			font-size: 52px;
			box-shadow: none;
			transition: all 1s ease-in-out;
		}

		.section .btn.btn-primary.next-section-button:hover {
			transform: scale(1.2);
		}

		.more h1,
		.more h2,
		.more h3,
		.more h4 {
			font-size: 19px;
			color: #85b221;
			padding-top: 10px;
			padding-bottom: 10px;
			text-transform: uppercase;
		}


		.more p {
			font-size: 18px;
			color: #575756;
			padding-top: 20px;
			padding-bottom: 20px;
		}

		.more .neos-nodetypes-text ul li {
			color: #575756;
		}

		.navbar-custom {
			min-height: 95px;
			margin-bottom: 0;
			border-bottom: 1px solid rgba(255, 255, 255, .3);
			background-color: rgba(0, 0, 0, .5);
		}

		.navbar-custom .navbar-brand {
			font-weight: 700;
		}

		.navbar-custom .navbar-brand:focus {
			outline: 0;
		}

		.navbar-custom .navbar-brand .navbar-toggle {
			padding: 4px 6px;
			font-size: 16px;
			color: #fff;
		}

		.navbar-custom .navbar-brand .navbar-toggle:focus,
		.navbar-custom .navbar-brand .navbar-toggle:active {
			outline: 0;
		}

		.navbar-custom a {
			color: #fff;
		}

		.navbar-custom .nav li a {
			padding-left: 30px;
			font-weight: 500;
		}

		.navbar-custom .nav li a:hover {
			color: #85b221;
			outline: 0;
			background: none;
		}

		.navbar-custom .nav li a:focus {
			color: #85b221;
			background-color: transparent;
		}

		.navbar-custom .nav li.active a {
			color: #85b221;
		}

		.navbar-custom .navbar-brand img {
			margin-top: -5px;
			height: 80px;
		}

		.navbar-custom .navbar-toggle .icon-bar {
			background-color: #fff;
		}

		.navbar-nav {
			margin-top: 20px;
		}

		.navbar-collapse {
			overflow-x: visible;
			padding-right: 15px;
			padding-left: 15px;
			border-top: 1px solid transparent;
			box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
			text-align: right;
		}



		.section form .row {
			margin: 20px 0;
		}

		.section form label {
			color: #ffffff;
			font-size: 15px;
			font-weight: 300;
			text-align: left !important;
		}

		.section form input,
		.section form textarea {
			padding: 5px 10px;
			background-color: rgba(60, 60, 59, 0.63);
			border: 2px solid rgba(255, 255, 255, .5);
			border-radius: 0;
			color: #fff;
			font-weight: 300;
		}

		.section form textarea {
			height: 205px;
			resize: none;
		}

		.section form input,
		.section form textarea:focus {
			outline: none;
		}

		.section form input,
		.section form .actions ul {
			list-style-type: none;
		}

		.section form .actions {
			margin-bottom: 100px;
		}

		.section form .datepicker {
			height: 205px;
			border: 2px solid rgba(255, 255, 255, .5);
			background-color: rgba(0, 0, 0, .5);
			color: #fff;
		}

		.section form .datepicker a {
			color: #fff;
			text-decoration: none;
		}

		.section form .datepicker td,
		.section form .datepicker th {
			padding: 0 10px;
			text-align: center;
			font-weight: 300;
		}

		.section form .datepicker .ui-datepicker-unselectable {
			color: rgba(255, 255, 255, .4);
		}

		.section form .datepicker .ui-datepicker-current-day {
			background-color: #ee7326;
		}

		.section form .datepicker .ui-datepicker-prev {
			float: left;
			cursor: pointer;
		}

		.section form .datepicker .ui-datepicker-next {
			float: right;
			cursor: pointer;
		}

		.section form .datepicker .ui-datepicker-title {
			text-align: center;
		}

		.section form .actions button.btn.btn-primary {
			float: right;
			border: 2px solid rgba(255, 255, 255, .5);
			border-radius: 0;
			background-color: rgba(0, 0, 0, .5);
			margin-bottom: 20px;
			margin-right: 16px;
			padding: 5px 45px;
			text-transform: uppercase;
		}

		.section form .help-inline {
			display: block;
			float: right;
			color: #ee7326;
			font-weight: 300;
		}

		.section form .help-block {
			color: #101010;
			background-color: #ffffff;
			border: 1px solid #101010;
			padding: 2px;

			a {
				color: #101010;
				font-weight: bold;
				text-decoration: underline;
			}
		}

		.gallery .gallery-item img {
			width: 100%;
			height: auto;
			border: 1px solid #ffffff;
		}

		.swiper-container-horizontal>.swiper-pagination {
			display: none;
			bottom: 10px;
			width: auto;
			top: auto;
			text-align: center;
			position: absolute;
		}

		.swiper-slide .content {
			display: none;
		}

		.swiper-slide .content .content-inner {
			position: relative;
			margin-top: 5%;


			.neos-nodetypes-image:after,
			.neos-nodetypes-textwithimage:after {
				clear: both;
			}

			.neos-nodetypes-image .neos-alignment-left,
			.neos-nodetypes-textwithimage .neos-alignment-left {
				float: left;
				padding-right: 10px;
			}

			.neos-nodetypes-image .neos-alignment-right,
			.neos-nodetypes-textwithimage .neos-alignment-right {
				float: right;
				padding-left: 10px;
			}

			.neos-nodetypes-image .neos-alignment-center,
			.neos-nodetypes-textwithimage .neos-alignment-center {
				text-align: center;
			}

			.neos-nodetypes-image .neos-alignment-center .img-responsive,
			.neos-nodetypes-textwithimage .neos-alignment-center .img-responsive {
				display: inline-block;
			}


			@media (min-width: $sm-view) {
				position: relative;
			}

			@media (min-width: $md-view) {
				position: relative;
				margin-top: 13%;
			}

		}


		.swiper-slide-next {
			max-height: 200px;
		}

	}
}

@media (min-width: $sm-view) {
	html {

		body {

			.section .btn.btn-primary.next-section-button {
				display: block;
			}

			.swiper-container.swiper-images {
				width: 80%;
				height: 430px;
				margin: 50px auto 0;
				padding: 50px 0;
			}

			.swiper-container.swiper-description {
				margin: 15px auto;
				width: 80%;
				text-align: center;
			}

			.swiper-container.swiper-description .swiper-slide>div {
				padding: 30px;
				font-size: 17px;
			}


			.swiper-container-horizontal>.swiper-pagination {
				display: none;
				bottom: 50px;
				width: auto;
				top: auto;
				text-align: center;
				position: absolute;
			}


			.swiper-slide .content {
				display: block;
				text-align: center;
			}

			.swiper-slide .content .content-inner img {
				width: auto;
				margin-top: 25px;
			}

			.swiper-slide-next {
				max-height: 300px;
			}
		}
	}
}

@media (min-width: $md-view) {
	html {
		body {

			.section .btn.btn-primary.next-section-button {
				display: block;
			}

			.swiper-container.swiper-images {
				width: 80%;
				height: 430px;
				margin: 50px auto 0;
				padding: 50px 0;
			}

			.swiper-container.swiper-description {
				margin: 15px auto;
				width: 80%;
				text-align: center;
			}

			.swiper-container.swiper-description .swiper-slide>div {
				padding: 30px;
				font-size: 17px;
			}

			.swiper-container-horizontal>.swiper-pagination {
				display: none;
				bottom: 15px;
				width: auto;
				top: auto;
				text-align: center;
				position: absolute;
			}

			.swiper-slide .content {
				display: block;
				text-align: center;
			}

			.swiper-slide .content .content-inner img {
				width: auto;
				margin-top: 25px;
			}

			.swiper-slide-next {
				max-height: 400px;
			}
		}
	}
}

@media (min-width: $lg-view) {
	html {
		body {

			.section .btn.btn-primary.next-section-button {
				display: block;
			}

			.swiper-container.swiper-images {
				width: 80%;
				height: 430px;
				margin: 50px auto 0;
				padding: 50px 0;
			}

			.swiper-container.swiper-description {
				margin: 15px auto;
				width: 80%;
				text-align: center;
			}

			.swiper-container.swiper-description .swiper-slide>div {
				padding: 30px;
				font-size: 17px;
			}


			.swiper-container-horizontal>.swiper-pagination {
				display: none;
				bottom: 50px;
				width: auto;
				top: auto;
				text-align: center;
				position: absolute;
			}

			.swiper-slide .content {
				display: block;
				text-align: center;
			}

			.swiper-slide .content .content-inner img {
				width: auto;
				margin-top: 25px;
			}

			.swiper-slide-next {
				max-height: 600px;
			}
		}
	}
}

html {
	// scroll-behavior: smooth;

	body {
		@import "overrides.scss";
	}
}